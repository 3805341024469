import React from 'react';
import { wg_what_makes_us_different } from '../../../images';

const AssessmentAboutDifference = () => {
	return (
		<div className='xl:grid xl:grid-cols-2 flex flex-col py-4 md:py-8 md:px-16 px-4 gap-4 container'>
			<div className='flex flex-col md:gap-6 gap-3 md:py-6 py-3'>
				<h2 className='flex text-xl md:text-3xl md:pb-3 pb-2'>
					<span>
						What Makes Working Genius&nbsp;
						<b>Different</b>
					</span>
				</h2>
				<p>
					First, it's simplicity. The Working Genius model distills any kind of work down to six fundamental
					activities. And in relatively short order, helps people identify the type of work that makes them
					thrive.
				</p>
				<p>
					Second, Working Genius is the only tool of its kind that is actually applied to work. Most
					personality instruments help people better understand how they are wired, but fail to explain how
					that fits together with others in the process of work. Working Genius solves this problem. Not only
					do individuals walk away with a greater understanding of the type of work that makes them thrive,
					but Working Genius provides teams with a better way to think about their dynamics, projects,
					meetings and even their hiring.
				</p>
				<p>
					Simply put, Working Genius is part personality assessment and part productivity tool for teams. And
					it is the simplest and fastest way to discover your gifts and transform your life.
				</p>
			</div>
			<div>
				<img
					src={wg_what_makes_us_different}
					alt='Enneagram, Myer Briggs, Predictive Index, DISC Profile, CliftonStrengths'
				/>
			</div>
		</div>
	);
};

export default AssessmentAboutDifference;
