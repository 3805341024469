import React, { useState } from 'react';
import Select from 'react-select';
import AppRIIcon from '../../../components/AppRIIcon';
import { black, gray } from 'tailwindcss/colors';

const RosterActionsDropdown = ({
	item,
	loadingReport,
	hasPermission,
	user,
	onSendReport,
	onShareActionClick,
	setSelectedRoster,
	setIsEditingAssessmentResults,
	setMoveAssessmentItem,
	setMoveAssessment,
	setIsDuplicate,
	setChangeNameItem,
	setIsEditingAssessmentName,
}) => {
	const [selectedOption, setSelectedOption] = useState(null);

	const dropdownActions = (item) =>
		[
			!!item.wg1 && {
				value: 'edit-results',
				label: 'Edit Assessment Results',
				icon: 'file-edit-line',
				disabled: !hasPermission('WG_ROSTER.PUT.WIDGET'),
				action: () => {
					setSelectedRoster(item);
					setIsEditingAssessmentResults(true);
				},
			},
			{
				value: 'rename',
				label: 'Edit Name on Assessment',
				icon: 'pencil-line',
				action: () => {
					setChangeNameItem(item);
					setIsEditingAssessmentName(true);
				},
			},
			{
				value: 'send-email',
				label: 'Email Report to User',
				icon: 'mail-send-line',
				loading: loadingReport === item.id,
				disabled:
					!hasPermission('WG_ROSTER.POST.REPORT') ||
					(!!item.shared_from && item.email !== user.customers_email_address),
				action: () => {
					onSendReport(item);
				},
			},
			{
				value: 'share',
				label:
					item.allow_share === 1 || item.email === user.customers_email_address
						? 'Share Assessment Report'
						: 'Request to share assessment',
				icon: 'share-box-line',
				disabled: !hasPermission('WG_ROSTER.POST.SHARE'),
				action: () => {
					onShareActionClick(item);
				},
			},
			{
				value: 'move',
				label: 'Move Assessment Report',
				icon: 'file-transfer-line',
				action: () => {
					setMoveAssessmentItem(item);
					setMoveAssessment(true);
				},
			},
			{
				value: 'duplicate',
				label: 'Duplicate Assessment',
				icon: 'file-copy-line',
				action: () => {
					setIsDuplicate(true);
					setMoveAssessmentItem(item);
					setMoveAssessment(true);
				},
			},
		].filter(Boolean);

	const options = dropdownActions(item).map(({ value, label, icon, disabled, loading }) => ({
		value,
		label: (
			<div className={`flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
				<AppRIIcon icon={icon} className='pr-2 text-secondary text-2xl' />
				<p>
					{loading ? <AppRIIcon icon='loader-4-line' className='action-icon animate-spin loading' /> : label}
				</p>
			</div>
		),
		isDisabled: disabled,
	}));

	const handleChange = (option) => {
		const selectedAction = dropdownActions(item).find((action) => action.value === option.value);
		if (selectedAction) selectedAction.action();
		setSelectedOption(null);
	};

	const customStyles = {
		menu: (provided) => ({
			...provided,
			zIndex: 50,
			position: 'absolute',
			width: 'max-content',
		}),
		control: (provided) => ({
			...provided,
			border: '1px solid #E5E7EB',
			borderRadius: '0px',
			boxShadow: 'none',
			width: 'max-content',
			cursor: 'pointer',
			'&:hover': {
				background: gray[100],
			},
		}),
		placeholder: (provided) => ({
			...provided,
			color: 'black',
			'&:hover': {
				color: '#263860',
			},
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused && 'transparent',
			color: 'black',
			cursor: 'pointer',
			'&:hover': {
				background: gray[100],
			},
		}),
	};

	return (
		<div className='relative'>
			<Select
				placeholder={'More Actions'}
				options={options}
				value={selectedOption}
				onChange={handleChange}
				menuPlacement='auto'
				styles={customStyles}
				components={{ IndicatorSeparator: () => null }}
				isSearchable={false}
				minMenuHeight={500}
			/>
		</div>
	);
};

export default RosterActionsDropdown;
